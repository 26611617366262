import React, { Component } from 'react';
import { connect } from 'react-redux';
import {SupportedLanguages} from "../helpers/myHelper";
import { Link } from "react-router-dom";
import { toggleLangSelector } from "../redux/actions";


class NavLanguageSelector extends Component {

    closeLangSelector = event => {
        this.props.dispatch(toggleLangSelector(false));
    }

    openLangSelector = event => {
        this.props.dispatch(toggleLangSelector(true));
    }

    render() {
        let changeLangStyles = {
            zIndex : '1',
            left : '35px'
        }
        //right: 88px
        let holderClassName = 'okbg-hs-lang-holder';
        let sliderPos = '0px';
        let pos = 0;
        const linkObjects = Object.keys(SupportedLanguages).map(lang_key => {
            const toPath = "?lang="+SupportedLanguages[lang_key];
            let leftPos = pos+'px'; pos+=30;
            if(this.props.lang === SupportedLanguages[lang_key]){
                sliderPos = '-'+ leftPos;                    
                return (
                    <React.Fragment key={SupportedLanguages[lang_key]}>
                        <span onClick={this.closeLangSelector} style={{left: leftPos}} className="okbg-hs-lang-selected">
                            <img className="okbg-hs-lang-img" data-pos={leftPos} src={process.env.PUBLIC_URL + '/images/'+SupportedLanguages[lang_key]+'.png'} alt={SupportedLanguages[lang_key]} />
                        </span>
                    </React.Fragment>
                )
            }
            else{
                return (
                    <React.Fragment key={SupportedLanguages[lang_key]}>
                        <Link onClick={this.closeLangSelector} style={{left: leftPos}} className="okbg-hs-lang-btn" to={toPath}>
                            <img className="okbg-hs-lang-img" data-pos={leftPos} src={process.env.PUBLIC_URL + '/images/'+SupportedLanguages[lang_key]+'.png'} alt={SupportedLanguages[lang_key]} />
                        </Link>
                    </React.Fragment>
        
                )
            }
        })
        //langOpened idwa ot redux state. setvam go flase kogato se zaredi
        //houses ili house. taka moje da se kontrolira i ot back history
        if(this.props.langOpened) {
            holderClassName = 'okbg-hs-lang-holder okbg-hs-lang-holder-opened';
            sliderPos = '0px';
            changeLangStyles = {
                zIndex : '-1',
                left : '500px'
            }
        }

        return (
            <React.Fragment>
            <div style={changeLangStyles} id="okbg-hs-change-lang" onClick={this.openLangSelector}>
                <svg style={{display: 'block', width: '16px', height: '17px', position: 'absolute', top: '4px', left: '0px', transform: 'scaleX(-1)'}}>
                    <path fill="#ffffff" d="M0.7,13.9L4,8.6L0.8,3.5C0.7,3.3,0.6,3.1,0.5,3S0.3,2.7,0.3,2.5S0.2,2.2,0.2,2
                    c0-0.4,0.2-0.7,0.5-1s0.7-0.4,1.1-0.4c0.7,0,1.3,0.5,2,1.4L7,6.8c0.5,0.7,0.8,1.3,0.8,1.8C7.7,9,7.5,9.5,7,10.3L3.9,15
                    c-0.4,0.6-0.8,1.1-1.1,1.3s-0.6,0.4-1,0.4c-0.5,0-0.9-0.1-1.2-0.4s-0.5-0.7-0.5-1.1C0.2,14.8,0.3,14.4,0.7,13.9z M8.7,13.9l3.3-5.3
                    L8.9,3.5C8.8,3.3,8.7,3.1,8.6,3S8.4,2.7,8.4,2.6S8.3,2.2,8.3,2c0-0.4,0.2-0.7,0.5-1s0.7-0.4,1.1-0.4c0.7,0,1.4,0.5,2,1.4l3.2,4.9
                    c0.3,0.4,0.5,0.7,0.6,1s0.2,0.5,0.2,0.8c0,0.3-0.3,0.9-0.8,1.7L12,15c-0.4,0.6-0.8,1.1-1.1,1.3s-0.6,0.4-1,0.4
                    c-0.5,0-0.8-0.1-1.2-0.4s-0.5-0.7-0.5-1.1C8.2,14.7,8.4,14.3,8.7,13.9z"/>
                </svg>	
            </div>
            <div id="okbg-hs-langHolder" className={holderClassName} style={{marginRight:"5px", marginLeft:"10px", position: "relative"}}>
                <div id="okbg-hs-lang-slider" className="okbg-hs-lang-slider" style={{ left: sliderPos }}>
                    {linkObjects}
                </div>
            </div>
            </React.Fragment>
        )

    }
}

const mapStateToProps = (state) => {
    //console.log(state.lang, state.langOpened);
    return {
      lang: state.mainReducer.lang,
      langOpened: state.mainReducer.langOpened
    }
}

export default connect(mapStateToProps)(NavLanguageSelector);