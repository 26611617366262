import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateMessagePopup } from "../redux/actions";
import { translations} from "../mats/translations";


class MessagePopup extends Component {

    componentWillUpdate = (data) => {
        console.warn(data);
    }

    closeMessagePopup = (event) => {
        this.props.dispatch(updateMessagePopup(""));
    }    

    render() {
        let theMessage = "";
        if(this.props.messagePopup!=="") {
            theMessage = translations[this.props.messagePopup][this.props.lang];
        }
        return (
            <React.Fragment>
                <div id="message-popup" className={this.props.messagePopup !== "" ? "message-popup-showing" : ""}>
                    <span id="closeMessagePopup" className="okbg-hs-side-nav-link okbg-hs-btn-close" style={{border: '0px', right: '5px'}} onClick={this.closeMessagePopup}>&times;</span>

                    <div className="okbg-hs-panel" style={{padding: '10px'}}>
                        <h2>{theMessage}</h2>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        messagePopup: state.mainReducer.messagePopup,
        lang: state.mainReducer.lang
    }
}

export default connect(mapStateToProps)(MessagePopup);