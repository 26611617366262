import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { translations} from "../mats/translations";
import { connect } from 'react-redux';

class FormLogin extends Component {

    render() {
        const { handleSubmit, pristine, submitting, lang} = this.props;

        //console.log("form render", this.props);
        return (
            <form id={this.props.id} onSubmit={handleSubmit}>
                <div className="okbg-hs-flex-container" style={{width: "220px", padding: "0px"}}>
                    <div>
                        <Field id="login_user" name="login_user" type="text" style={{textTransform: 'none !important'}} component={renderField} label={translations.username[lang]}/>
                        <Field id="login_email" name="login_email" type="email" component={renderField} label={translations.email[lang]}/>
                    </div>
                    <button type="submit" className="pinnedbtns" style={{width: '220px'}} disabled={pristine || submitting}>{translations.login[lang]}</button>
                </div>
            </form>
        )
    }
}


const renderField = ({ id, input, label, type, meta: { touched, error, warning } }) => (
    <div>
      <div>
        <input {...input} placeholder={label} type={type} id={id} style={{textTransform:"none", margin:"0px 1px 10px 1px", width: "220px"}}/>
        {touched && ((error && <span className="okbg-hs-error-validate">{error}</span>) || (warning && <span className="okbg-hs-error-validate">{warning}</span>))}
      </div>
    </div>
)


const validate = values => {
    const errors = {}
    if (!values.contact_email) {
      errors.contact_email = translations.email_is_required[values.contact_lang]
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)) {
      errors.contact_email = translations.invalid_email_address[values.contact_lang]
    }
    return errors
}


FormLogin = reduxForm({
    form: 'login_form',
    validate
    /*
    //shte gi wzema ot redux state-a
    initialValues: { ptype_id: "id_0", plocation_ids: "0", input_min: 0, input_max: 5000
    },
    */
    //wzemam initialstate ot redux i e dobre da destroyna za da moga da go wzema pak.
    /*destroyOnUnmount: false*/
})(FormLogin)

FormLogin = connect(
    state => ({
        initialValues: {
            login_email: state.mainReducer.userEmail === "N/A" ? "": state.mainReducer.userEmail,
        }
    })
)(FormLogin)

export default FormLogin