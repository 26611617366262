import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getParameterValue } from '../helpers/myHelper';
import { changeLanguage } from "../redux/actions";
import { translations} from "../mats/translations";
import { Helmet } from 'react-helmet';

class PrivacyPolicy extends Component {

    componentDidUpdate(prevProps) {
        this.props.dispatch(changeLanguage(this.props.lang));
    }
    render() {
        return (
            <React.Fragment>
                <Helmet>
                <title>{translations.privacy_policy[this.props.lang]}</title>
                <meta name="description" content={translations.privacy_policy[this.props.lang] + " " + translations.head2[this.props.lang] + " :: OK Bulgaria"} />
                </Helmet>
                <h1>{translations.privacy_policy[this.props.lang]}</h1>
                <div className="okbg-hs-panel okbg-hs-padding5">
                    <p>This privacy policy ("policy") will help you understand how OK Bulgaria ("us", "we", "our") uses and protects the data you provide to us when you visit and use m.okbulgaria.com ("website", "service").</p>
                    <p>We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.</p>
                    <h2>What User Data We Collect</h2>
                    <p>When you subscribe to the website, we may collect the following data:</p>
                    <ul>
                    <li>Your IP address.</li>
                    <li>Your email address.</li>
                    </ul>
                    <p>We are collecting your data for the following reasons:</p>
                    <ul>
                    <li>To improve our services.</li>
                    <li>To send you promotional emails containing the information you subscribed for.</li>
                    </ul>
                    <p>OK Bulgaria is committed to securing your data and keeping it confidential. OK Bulgaria has done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.</p>
                    <h2>Our Cookie Policy</h2>
                    <p>The data we collect by using cookies is used to customize our website to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.</p>
                    <p>Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.</p>
                    <p>If you want to disable cookies, you can do it by accessing the settings of your internet browser. (Provide links for cookie settings for major internet browsers).</p>
                    <h2>Terms of use</h2>
                    <p>Whilst OK Bulgaria make every attempt to ensure the accuracy and reliability of the information contained in its website, this information should not be relied upon as a substitute for formal advice from OK Bulgaria and its partners. OK Bulgaria, its employees and agents will not be responsible for any loss, however arising, from the use of, or reliance on this information.</p>
                    <p>This website is provided <strong>as is</strong> without warranty of any kind, either expressed or implied. You should not assume that this website is error-free or that it will be suitable for the particular purpose which you have in mind when using it. OK Bulgaria assumes no responsibility for errors or omissions in this website or other documents which are referenced by or linked to this website. In no event shall OK Bulgaria be liable for any special, incidental, indirect or consequential damages of any kind, or any damages whatsoever, including, without limitation, those resulting from loss of use, data or profits, whether or not advised of the possibility of damage, and on any theory of liability, arising out of or in connection with the use or performance of this website or other documents which are referenced by or linked to this website.</p>
                    <p>This website could include technical or other inaccuracies or typographical errors. OK Bulgaria may make improvements and/or changes in the services or facilities described in this website at any time.</p>
                    <p>This website is available internationally and may contain references to OK Bulgaria services that are not available in your country. These references do not imply that OK Bulgaria intend to make such services available in your country.</p>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let queryLang = getParameterValue(ownProps.location.search, "lang");
    return {
        lang: queryLang,
    }
}



export default  connect(mapStateToProps)(PrivacyPolicy);
