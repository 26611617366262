import { applyMiddleware, combineReducers, createStore } from 'redux';
import { mainReducer } from "./reducer";
import { reducer as formReducer } from 'redux-form';
import thunk from "redux-thunk";//to be able to use async actions (fetch)

//import logger from "redux-logger";//to be able to monitor store
//const middleware = applyMiddleware(thunk, logger);

//form name for the formReducer is mandatory
const rootReducer = combineReducers({
    mainReducer: mainReducer,
    form: formReducer
})

const middleware = applyMiddleware(thunk);
export default createStore(rootReducer, middleware);