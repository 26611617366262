import React, { Component } from 'react';
import loaderSVG from '../images/loader.svg';
import { connect } from 'react-redux';


class LoadingCover extends Component {
  render() {
    return (
      <div id="loading-cover" className={this.props.loading ? "loading-cover-showing" : ""}>
        <img src={loaderSVG} alt="loading" />
        <p>{this.props.lang}</p>
      </div>
    )
  }
}
const mapStateToProps = state => {
    return {
        loading: state.mainReducer.loading
    }
}

export default connect(mapStateToProps)(LoadingCover);